<template>
  <div class="mega-menu-mobile-item">
    <slot name="mobile-navigation-item">
      <span
        :class="[codeAkeneo, cattype == 1 ? 'mega-menu-mobile-item-link-additional' : ''] "
        class="mega-menu-mobile-item-link mega-menu-mobile-item-link-level-1 "
        @click="openSubMenu(index)"
      >
        <span class="label">
          {{ label }}
        </span>
        <span class="sf-icon">
          <SvgImage
            height="7"
            icon="Icon_chevron"
            width="14"
          />
        </span>
      </span>
      <div
        v-if="items && submenuItemIndex === index"
        :class="'mega-menu-dropdown-content-'+index"
        class="mega-menu-mobile-dropdown-content"
      >
        <div class="mega-menu-mobile-dropdown-internal-content">
          <div class="mega-menu-mobile-back" @click="closeSubMenu()">
            <span class="sf-icon">
              <SvgImage
                height="20"
                icon="Icon_fleche"
                width="20"
              />
            </span>
            <span class="label">
              {{ label }}
            </span>
          </div>
          <div class="mega-menu-mobile-item">
            <SfLink
              :link="link"
              class="mega-menu-mobile-item-link mega-menu-mobile-item-link-seeall"
              @click.native="menuMobileClose()"
            >
              <span class="label">
                {{ $t('See all') }}
              </span>
              <span class="sf-icon">
                <SvgImage
                  height="7"
                  icon="Icon_chevron"
                  width="14"
                />
              </span>
            </SfLink>
          </div>
          <div
            v-for="(subContent) in items"
            v-if="(subContent.product_count > 0 || subContent.category_type === 1 ) && subContent.include_in_menu === 1"
            class="mega-menu-mobile-dropdown-sub-content"
          >
            <div class="mega-menu-mobile-item">
              <SfLink
                :link="localePath(getAgnosticCatLink(subContent))"
                class="mega-menu-mobile-item-link"
                @click.native="menuMobileClose()"
              >
              <span class="label">
                {{ subContent.name }}
              </span>
                <span class="sf-icon">
                <SvgImage
                  height="7"
                  icon="Icon_chevron"
                  width="17"
                />
              </span>
              </SfLink>
            </div>
          </div>
        </div>
      </div>
    </slot>
  </div>
</template>
<script>
import { useUiHelpers, useUiState } from '~/composables';
import SvgImage from '~/components/General/SvgImage.vue';
import { SfLink } from '@storefront-ui/vue';
import { ref } from '@nuxtjs/composition-api';

export default {
  name: 'HeaderNavigationItemMobile',
  components: {
    SvgImage,
    SfLink,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    link: {
      type: [String, Object],
      default: '',
    },
    items: {
      type: Array,
      default() {
        return []
      }
    },
    index: {
      type: [String, Number],
      default: '',
    },
    cattype: {
      type: Number,
      default: '',
    },
		codeAkeneo: {
			type: String,
			default: '',
		}
  },

  setup() {
    const { getCatLink: getAgnosticCatLink } = useUiHelpers();
    const submenuItemIndex                   = ref(null);

    const openSubMenu = (submenuIndex) => {
      submenuItemIndex.value = submenuIndex;
    };

    const closeSubMenu = () => {
      submenuItemIndex.value = null;
    };

    const { toggleMobileMenu } = useUiState();
    const menuMobileClose      = () => {
      toggleMobileMenu();
      document.body.classList.remove('no-scroll');
    };

    return {
      getAgnosticCatLink,
      submenuItemIndex,
      openSubMenu,
      closeSubMenu,
      menuMobileClose,
    }
  }
};
</script>

<template>
  <div
    class="mega-menu-item"
    :class="{
      'mega-menu-item--open' : isMenuOpen,
      'mega-menu-item--content': cattype === 1,
      'mega-menu-item--ecom': cattype === 0
    }"
    v-click-outside="hideMenu"
  >
    <slot name="navigation-item">
      <a
        class="mega-menu-item-link mega-menu-item-link-main"
				:class="codeAkeneo"
        v-if="items.length !== 0"
        @click="toggleMenu()"
      >
        {{ label }}
      </a>
      <SfLink v-else
              class="mega-menu-item-link mega-menu-item-link-main"
              :link="link"
      >
        {{ label }}
      </SfLink>
      <div
        v-if="items"
        :class="'mega-menu-dropdown-content-'+index"
        class="mega-menu-dropdown-content"
      >
				<div class="mega-menu-dropdown-actions">
					<SfLink class="mega-menu-item-link-category" :link="link" @click.native="hideMenu()">
						{{ label }}
					</SfLink>

					<div class="close-button" @click="hideMenu()">
						<SvgImage
								height="12"
								icon="close"
								width="12"
						/>
					</div>
				</div>
        <div class="menu-zone">
          <div class="mega-menu-dropdown-internal-content">
            <div class="mega-menu-dropdown-internal-content-text">
              <div
                v-for="(subContent) in items"
                v-if="(subContent.product_count > 0 || subContent.category_type === 1 ) && subContent.include_in_menu === 1"
                class="mega-menu-dropdown-sub-content"
              >
                <SfLink
                  :link="localePath(getAgnosticCatLink(subContent))"
                  class="mega-menu-dropdown-header"
                  @click.native="hideMenu(subContent)"
                >
                  {{ subContent.name }}
                  <span class="mega-menu-dropdown-separator"></span>
                </SfLink>
                <div
                  class="mega-menu-dropdown-links"
                >
                  <div
                    v-for="(cat) in subContent.children"
                    v-show="(cat.product_count > 0 || cat.category_type === 1 ) && cat.include_in_menu === 1"
                  >
                    <SfLink
                      :link="localePath(getAgnosticCatLink(cat))"
                      @click.native="hideMenu(cat)"
                    >
                      {{ cat.name }}
                    </SfLink>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-show="image"
              class="mega-menu-dropdown-cms"
            >
              <a :href="imageLink">
                <img v-show="image" :src="image" alt="category-image" class="mega-menu-dropdown-cms__img"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </slot>
  </div>
</template>
<script>
import { useUiHelpers } from '~/composables';
import { SfLink } from '@storefront-ui/vue';
import { ref, useRouter } from '@nuxtjs/composition-api';
import { clickOutside } from "~/components/directives/click-outside/click-outside-directive";
import SvgImage from "~/components/General/SvgImage.vue";

export default {
  name: 'HeaderNavigationItem',
  directives: { clickOutside },
  components: {
    SvgImage,
    SfLink,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    link: {
      type: [String, Object],
      default: '',
    },
    items: {
      type: Array,
      default() {
        return []
      }
    },
    index: {
      type: [String, Number],
      default: '',
    },
    image: {
      type: [String],
      default: '',
    },
    imageLink: {
      type: [String],
      default: '',
    },
    cattype: {
      type: Number,
      default: 0,
    },
		codeAkeneo: {
			type: String,
			default: '',
		}
  },
  setup() {
    const { getCatLink: getAgnosticCatLink } = useUiHelpers();
    const router                             = useRouter();

    const isMenuOpen = ref(false)

    const hideMenu = (subContent = null) => {
      isMenuOpen.value = false
    }

    const showMenu = () => {
      isMenuOpen.value = true
    }

    const toggleMenu = (subContent = null) => {
      if (isMenuOpen.value) {
        hideMenu(subContent)
      } else {
        showMenu()
      }
    }

    const getCms = (cmsToDecode) => {
      if (cmsToDecode) {
        let res = cmsToDecode.replace(/&amp;/g, '&');
        res     = res.replace(/&lt;/g, '<');
        res     = res.replace(/&gt;/g, '>');
        return res;
      }
    };

    return {
      getAgnosticCatLink,
      getCms,
      hideMenu,
      toggleMenu,
      router,
      isMenuOpen
    }
  }
};
</script>

/* eslint-disable  */

export default {
  "An error occurred": "Une erreur est survenue",
  "Back to Home page": "Retour à l'accueil",
  "Page not found": "Oups... Cette page n'existe plus",
  "{0} has been successfully removed from your cart": "{0} has been successfully removed from your cart",
  "{n} product found": "{n} produit trouvé",
  "{n} product": "{n} produit",
  "{n} products found": "{n} produits trouvés",
  "1 product": "1 product",
  "8 characters minimum including 1 uppercase, 1 lowercase, 1 number or 1 symbol": "8 caractères minimum dont 1 majuscule, 1 minuscule, 1 chiffre ou 1 symbole",
  "A customer with the same email address already exists in an associated website.": "Cette adresse mail est déjà utilisée par un autre utilisateur.",
  "About us": "About us",
  "Access to your individual space": " Accèdez à votre espace particulier",
  "Access to your professional space": "Accédez à votre espace professionnel",
  "Access your custom prices and manage your account safely !": "Accédez à vos prix personnalisés et gérez votre compte en toute sécurité !",
  "Account opening date": "Date d'ouverture de compte",
  "Account": "Account",
  "Add a review": "Add a review",
  "Add all to cart": "Tout ajouter au panier",
  "Add new address": "Add new address",
  "Add review": "Add review",
  "Add the address": "Add the address",
  "Add to cart": "Ajouter au panier",
  "Add to compare": "Add to compare",
  "Add to Wishlist": "Add to Wishlist",
  "Additional Information": "Additional Information",
  "Address": "Adresse",
  "Addresses details": "Addresses details",
  "All Orders": "All Orders",
  "Allow order notifications": "Allow order notifications",
  "AllProductsFromCategory": "All {categoryName}",
  "Already got a web access ?": "Vous avez déjà un accès web ?",
  "Amount including tax": "Montant TTC",
  "Amount": "Amount",
  "ANNULEE": "Annulée",
  "Applied Coupon": "Applied Coupon",
  "Apply filters": "Apply filters",
  "Apply": "Apply",
  "Approve": "Approuver",
  "Are you an individual customer ?": "Vous êtes un particulier ?",
  "Are you an individual customer": "Are you an individual customer",
  "Are you sure you would like to remove this item from the shopping cart?": "Are you sure you would like to remove this item from the shopping cart?",
  "Are you sure?": "Are you sure?",
  "Attention!": "Attention!",
  "Back to home": "Back to home",
  "Back to homepage": "Back to homepage",
  "Bank account": "Compte bancaire",
  "Bank": "Banque",
  "Be aware of upcoming sales and events. Receive gifts and special offers!": "Be aware of upcoming sales and events. Receive gifts and special offers",
  "Billing address": "Adresse de facturation",
  "Billing": "Billing",
  "Both passwords must be the same": "Les deux mots de passe doivent être identiques",
  "Brand": "Brand",
  "Calculate your quantity": "Calculer votre quantité",
  "Cancel": "Annuler",
  "Cart": "Cart",
  "Categories": "Nos catégories",
  "Change of store": "Choisir un magasin",
  "Change password your account": "If you want to change the password to access your account, enter the following information",
  "Change Store": "Change Store",
  "Change to grid view": "Change to grid view",
  "Change to list view": "Change to list view",
  "Change": "Change",
  "Choose a color": "Choisir une couleur",
  "Choose a conditioning": "Choisir un conditionnement",
  "Choose a configuration": "Choisir une configuration",
  "Choose a model": "Choisir un modèle",
  "Choose a quantity": "Choisir une quantité",
  "Choose Currency": "Choose Currency",
  "Choose your store": "Choisissez votre magasin",
  "City": "Ville",
  "Clear all": "Clear all",
  "Cocktail & Party": "Cocktail & Party",
  "Color: ": "Couleur : ",
  "Color": "Color",
  "Colorful summer dresses are already in store": "Colorful summer dresses are already in store",
  "Comment": "Comment",
  "Commercial information": "and agree to receive personalized commercial information from Brand name by email",
  "Company Name": "Nom de l'entreprise",
  "Complement": "Complément d'adresse",
  "Conditioning: ": "Conditionnement : ",
  "Confirm your new password": "Confirmer votre nouveau mot de passe",
  "Connect": "Me connecter",
  "Contact details updated": "Keep your addresses and contact details updated.",
  "Contact us": "Contact us",
  "Continue to billing": "Continue to billing",
  "Continue to payment": "Continue to payment",
  "Continue to shipping": "Continue to shipping",
  "Cookies Policy": "Cookies Policy",
  "Country": "Country",
  "Create an account": "Create an account",
  "Create my web access": "Créer mon accès web",
  "Create your password": "Créer votre mot de passe",
  "Current Password": "Current Password",
  "Currently, you have no orders to validate": "Actuellement, vous n'avez pas de commandes à valider",
  "Customer Reviews": "Customer Reviews",
  "Customer service": "Customer service",
  "Date": "Date",
  "Default Billing Address": "Default Billing Address",
  "Default Shipping Address": "Default Shipping Address",
  "Delete": "Supprimer",
  "Departments": "Departments",
  "Description": "Description",
  "Details and status orders": "Check the details and status of your orders in the online store. You can also cancel your order or request a return.",
  "Did you request to reset your password ?": "Vous avez demandé la réinitialisation de votre mot de passe ?",
  "Discount": "Discount",
  "DISPONIBLE": "Disponible",
  "Do not miss": "À ne pas manquer",
  "Documents": "Documentations techniques",
  "Done": "Done",
  "dop_declarationofperformance": "Déclaration de performance",
  "dop_declarationofperformance2": "Déclaration de performance 2",
  "dop_declarationofperformance3": "Déclaration de performance 3",
  "selection_guide": "Guide de choix",
  "dop": "Déclaration de performance",
  "Download all": "Download all",
  "Download our application to your mobile": "Download our application to your mobile",
  "Download": "Télécharger",
  "Download bill": "Télécharger la facture",
  "Dresses": "Dresses",
  "Eco Sandals": "Eco Sandals",
  "Edit": "Edit",
  "Email address": "Email address",
  "EMP": "Retrait en magasin",
  "Empty bag": "Empty bag",
  "Empty": "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.",
  "EN_ATTENTE": "En attente",
  "EN_COMMANDE": "En commande",
  "EN_PREPARATION": "En préparation",
  "Enjoy your free account": "Enjoy these perks with your free account!",
  "Enter different address": "Enter different address",
  "Enter promo code": "Enter promo code",
  "EUR": "€",
  "Excluding tax": "HT",
  "EXPEDIEE": "Expédiée",
  "Fashion to take away": "Fashion to take away",
  "Features": "Caractéristiques",
  "Feedback": "Your feedback is important to us. Let us know what we could improve.",
  "Feel free to edit": "Feel free to edit any of your details below so your account is always up to date",
  "Filter by:": "Filtrer par :",
  "Filter by": "Filtrer par",
  "Filters": "Filtrer",
  "Find a store": "Trouver un magasin",
  "Find out more": "Find out more",
  "Find stunning women's cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.": "Find stunning women's cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.",
  "First Name": "First Name",
  "Firstname": "Prénom",
  "Forgot Password Modal Email": "Email you are using to sign in:",
  "Forgot Password": "If you can’t remember your password, you can reset it.",
  "Forgot your password ?": "Mot de passe oublié ?",
  "forgotPasswordConfirmation": "Thanks! If there is an account registered with the {0} email, you will find message with a password reset link in your inbox.",
  "Forgotten password ?": "Mot de passe oublié ?",
  "Forgotten password?": "Forgotten password?",
  "From": "From",
  "Go back shopping": "Continuer mes achats",
  "Go back to shop": "Retour à l'accueil",
  "Go back": "Go back",
  "Go to checkout": "Go to checkout",
  "Go To Product": "Go To Product",
  "Guarantee": "Guarantee",
  "Help & FAQs": "Help & FAQs",
  "Help": "Help",
  "hide": "hide",
  "Home": "Accueil",
  "House/Apartment number": "House/Apartment number",
  "I agree to": "I agree to",
  "I confirm subscription": "I confirm subscription",
  "I want to create an account": "I want to create an account",
  "In your store": "Dans votre magasin",
  "Including tax": "TTC",
  "Indicate your company coordonates found at the INSEE register": "Indiquez les coordonnés de votre entreprise figurant au registre INSEE :",
  "Info after order": "You can log to your account using e-mail and password defined earlier. On your account you can edit your profile data, check history of transactions, edit subscription to newsletter.",
  "insee error": "Incident technique",
  "Instruction1": "Take care of me",
  "Instruction2": "Just here for the care instructions?",
  "Instruction3": "Yeah, we thought so",
  "Internal server error": "Incident technique, veuillez réessayer ultérieurement. Pour toutes questions urgentes merci de contacter la hotline.",
  "Invalid email": "Email invalide",
  "Invalid format": "Format invalide",
  "Invoice reference": "Référence facture",
  "It was not possible to request a new password, please check the entered email address.": "It was not possible to request a new password, please check the entered email address.",
  "Item": "Item",
  "Items": "Items",
  "Kidswear": "Kidswear",
  "Last Name": "Last Name",
  "Lastname": "Nom",
  "Learn more": "Learn more",
  "Let’s start now – we’ll help you": "Let’s start now – we’ll help you.",
  "Linen Dresses": "Linen Dresses",
  "LIV": "Livraison",
  "LIVREE": "Livrée",
  "Log into your account": "Log into your account",
  "Log out": "Me déconnecter",
  "login in to your account": "login in to your account",
  "Login": "Login",
  "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.": "Il n'y a aucun article dans votre panier.",
  "Looks like you haven’t added any items to the Wishlist.": "Looks like you haven’t added any items to the Wishlist.",
  "Make an order": "Make an order",
  "Manage addresses": "Manage all the addresses you want (work place, home address...) This way you won\"t have to enter the address manually with each order.",
  "Manage billing addresses": "Manage all the billing addresses you want (work place, home address...) This way you won\"t have to enter the billing address manually with each order.",
  "Manage shipping addresses": "Manage all the shipping addresses you want (work place, home address...) This way you won\"t have to enter the shipping address manually with each order.",
  "Match it with": "Match it with",
  "Maximum length reached": "Taille maximale requise atteinte",
  "may only contain alphabetic characters as well as spaces and -": "Uniquement les lettres, l'espace et le tiret - qui sont acceptés",
  "may only contain alphabetic characters as well as spaces, - and apostrophe": "Uniquement les lettres, l'espace et le tiret - et l'apostrophe qui sont acceptés",
  "may only contain alphabetic characters as well as spaces, -, apostrophe and numbers": "Uniquement les lettres, les chiffres, l'espace, le tiret - et l'apostrophe qui sont acceptés",
  "Men fashion": "Men fashion",
  "Menu": "Menu",
  "Minimum length not reached": "Taille minimale requise non atteinte",
  "Mobile phone": "N° de mobile",
  "Model: ": "Modèle : ",
  "month01": "Janvier",
  "month02": "Février",
  "month03": "Mars",
  "month04": "Avril",
  "month05": "Mai",
  "month06": "Juin",
  "month07": "Juillet",
  "month08": "Aout",
  "month09": "Septembre",
  "month10": "Octobre",
  "month11": "Novembre",
  "month12": "Décembre",
  "My account information": "Mes informations de compte",
  "My Account": "Mon compte",
  "My account": "Mon compte",
  "My billing and shipping address are the same": "My billing and shipping address are the same",
  "My billing information": "Mes informations de facturation",
  "My Cart": "Mon panier",
  "My contact preferences": "Mes préférences de contact",
  "My credit notes": "Mes avoirs",
  "My dashboard": "Mon tableau de bord",
  "My frequent purchases": "Mes achats fréquents",
  "My general conditions": "Mes CGV/CGU",
  "My invoices and credits note": "Mes factures et avoirs",
  "My newsletter": "My newsletter",
  "My number": "Mon numéro",
  "My orders": "My orders",
  "My password": "Mon mot de passe",
  "My profile": "Mes informations",
  "My quotations": "Mes offres de prix",
  "My reviews": "My reviews",
  "My sub accounts": "Mes sous-comptes",
  "My wishlist": "Mes favoris",
  "name_ASC": "Ordre alphabétique",
  "name_DESC": "Ordre alphabétique inversé",
  "Name": "Name",
  "Need help ?": "Besoin d'aide ?",
  "New Password": "New Password",
  "New Products": "New Products",
  "New products": "Nouveautés",
  "No account": "Don't have an account yet?",
  "No invoice or credit found": "Vous n'avez pas de factures ni d'avoirs",
  "No product found.": "Aucun produit trouvé.",
  "No": "No",
  "Online": "En ligne",
  "or fill the details below": "or fill the details below",
  "or": "or",
  "Order by:": "Passée par :",
  "Order by": "Passée par",
  "Order details": "Détail de ma commande",
  "Order has been rejected": "La commande a bien été rejetée.",
  "Order history": "Mes commandes",
  "Order ID": "Order ID",
  "Order information": "Order information",
  "Order No.": "Commande n°",
  "Order passed": "Commande du",
  "Order reference:": "Numéro de commande :",
  "Order summary": "Order summary",
  "Order Total": "Order Total",
  "Order waiting for approval": "Commande en attente d'approbation",
  "Orders waiting for approval": "Commandes en attente d'approbation",
  "Other colors": "Autres couleurs",
  "Other products you might like": "Other products you might like",
  "Our Best sellers": "Nos meilleures ventes",
  "Our suggestions": "Nos suggestions",
  "Out of stock": "Out of stock",
  "PARTIEL_RETIREE": "Partiellement retirée",
  "PARTIELLE": "Partiellement disponible",
  "Password change": "Password change",
  "Password Changed": "Password successfuly changed. You can now go back to homepage and sign in.",
  "Password confirmation": "Confirmation de mot de passe",
  "Password": "Mot de passe",
  "Passwords don't match": "Passwords don't match",
  "Pay for order": "Pay for order",
  "Payment & Delivery": "Payment & Delivery",
  "Payment date": "Payment date",
  "Payment deadline": "Échéance de paiement",
  "Payment method": "Mode de paiement",
  "Payment Method": "Payment Method",
  "Payment Methods": "Payment Methods",
  "Payment": "Payment",
  "Personal data": "Personal data",
  "Personal Details": "Personal Details",
  "Personal details": "Personal details",
  "phone must begin with 06 or 07": "N° de mobile doit commencer par 06 ou 07",
  "Phone number must be 10 characters long and made of numbers between 0 and 9": "Le numéro de téléphone doit faire 10 caractères et comporter uniquement des chiffres de 0 à 9",
  "Phone number": "Phone number",
  "Please enter a valid mail address (Ex: johndoe@domain.com)": "Veuillez entrer une addresse mail valide (Ex: johndoe@domain.com)",
  "Please insert your password": "Veuillez saisir votre mot de passe",
  "Please select a country first": "Please select a country first",
  "Please select an item": "Merci de sélectionner une option",
  "Please type your current password to change your email address.": "Please type your current password to change your email address.",
  "Postal code": "Code postal",
  "PREPA_PARTIELLE": "Préparation avec reliquat",
  "price_ASC": "Prix croissant",
  "price_DESC": "Prix décroissant",
  "Price": "Price",
  "Primary contacts for any questions": "Primary contacts for any questions",
  "Privacy Policy": "Privacy Policy",
  "Privacy": "Privacy",
  "Product of": "produit sur",
  "Product suggestions": "Product suggestions",
  "Product": "Product",
  "Products found": "Produits trouvés",
  "Products of": "produits sur",
  "Products": "Produits",
  "Purchase terms": "Purchase terms",
  "Quality in the details": "Quality in the details",
  "Quantity": "Quantity",
  "Read all reviews": "Read all reviews",
  "Read and understand": "I have read and understand the",
  "Read next": "Lire la suite",
  "Read reviews": "Read reviews",
  "Receiver": "Destinataire",
  "Register today": "Register today",
  "Register": "Register",
  "Reject": "Rejeter",
  "Remove Address": "Remove Address",
  "Remove filters": "Effacer les filtres",
  "Remove from Wishlist": "Remove from Wishlist",
  "Remove": "Remove",
  "Repeat Password": "Repeat Password",
  "Reset Password": "Reset Password",
  "RETIREE": "Retirée",
  "Returns": "Returns",
  "Review my order": "Review my order",
  "Review": "Review",
  "safety_data_sheet_pdf": "Fiche de données de sécurité (FDS)",
  "environmental_and_health_data_sheet_pdf": "Fiche de déclaration environnementale et sanitaire (FDES)",
  "Same as shipping address": "Same as shipping address",
  "Save changes": "Save changes",
  "Save for later": "Save for later",
  "Save Password": "Save Password",
  "Search for items": "Que recherchez-vous ?",
  "Search results": "Search results",
  "Search": "Search",
  "Sections that interest you": "Sections that interest you",
  "See all my frequent purchases": "Voir tous mes achats fréquents",
  "See all results": "See all results",
  "See all": "Voir tout",
  "See more products": "Voir plus de produits",
  "See more": "See more",
  "Select payment method": "Select payment method",
  "Select previously saved address": "Select previously saved address",
  "Select shipping method": "Select shipping method",
  "Send my feedback": "Send my feedback",
  "Set as default billing": "Set as default billing",
  "Set as default shipping": "Set as default shipping",
  "Set up newsletter": "Set up your newsletter and we will send you information about new products and trends from the sections you selected every week.",
  "Share Your Look": "Share Your Look",
  "Share your look": "Share your look",
  "Shipping address": "Shipping address",
  "Shipping details": "Shipping details",
  "Shipping method": "Shipping method",
  "Shipping": "Shipping",
  "Shop now": "Shop now",
  "Show all products": "Show all products",
  "Show details": "Consulter",
  "Show less": "Show less",
  "Show more": "Show more",
  "show more": "show more",
  "Show on page": "Show on page",
  "Show": "Show",
  "Sign in": "Sign in",
  "Sign Up for Newsletter": "Sign Up for Newsletter",
  "siret number does not exist.": "Ce N° de SIRET n'existe pas.",
  "SIRET number must be 14 characters long and made of numbers between 0 and 9": "Le numéro de SIRET doit faire 14 caractères et comporter uniquement des chiffres de 0 à 9",
  "Size guide": "Size guide",
  "SO": "En magasin",
  "S2": "Par téléphone",
  "Society (optional)": "Société (facultatif)",
  "Some characters are forbidden": "Certains caractères ne sont pas autorisés",
  "Sort by": "Trier par",
  "Sort: Default": "Pertinence",
  "Sort: Name A-Z": "Ordre alphabétique",
  "Sort: Name Z-A": "Ordre alphabétique inversé",
  "Sort: Price from high to low": "Prix décroissant",
  "Sort: Price from low to high": "Prix croissant",
  "Special Offers": "Promotions",
  "Start shopping": "Start shopping",
  "State/Province": "State/Province",
  "Status": "Status",
  "Store localisation": "Localisation du magasin",
  "Street Name": "Street Name",
  "Subscribe to newsletter": "Subscribe to newsletter",
  "Subscribe to Newsletters": "Subscribe to Newsletters",
  "Subscribe": "Subscribe",
  "subscribeToNewsletterModalContent": "After signing up for the newsletter, you will receive special offers and messages from VSF via email. We will not sell or distribute your email to any third party at any time. Please see our {0}.",
  "Subtotal price": "Subtotal price",
  "Subtotal": "Subtotal",
  "Successful placed order": "You have successfully placed the order. You can check status of your order by using our delivery status feature. You will receive an order confirmation e-mail with details of your order and a link to track its progress.",
  "SUMMER COLLECTION {year}": "SUMMER COLLECTION {year}",
  "Summer Sandals": "Summer Sandals",
  "SW": "En ligne",
  "T-Shirts": "T-Shirts",
  "technical_data_sheet_pdf_2": "Règlement CLP",
  "technical_data_sheet_pdf": "Fiche technique",
  "Tell us how you would like to receive our information: change of commercial contact, change of hours, promotion, novelty, store event...": "Dites-nous de quelle façon vous souhaitez recevoir nos informations : changement de contact commercial, changement d’horaires, promotion, nouveauté, événement magasin…",
  "Terms and conditions": "Terms and conditions",
  "Thank you for your order!": "Thank you for your order!",
  "Thank You Inbox": "If the message is not arriving in your inbox, try another email address you might’ve used to register.",
  "Thank you": "Thank you",
  "Thanks! If there is an account registered with the": "S'il y a un compte associé à l’adresse",
  "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.": "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.",
  "The coupon code isn't valid. Verify the code and try again.": "The coupon code isn't valid. Verify the code and try again.",
  "The email field must be a valid email": "Le champ email doit être un email valide",
  "The field should have at least 2 characters": "Le champ doit avoir au moins 2 caractères",
  "The field should have at least 4 characters": "Le champ doit avoir au moins 4 caractères",
  "The field should have at least 8 characters": "Le champ doit avoir au moins 8 caractères",
  "The initialization link has expired. Please try again.": "Le lien de création/réinitialisation de mot de passe a expiré. Veuillez réessayer en ",
  "The mobile phone must be in a valid format": "Le numéro de mobile doit être dans un format valide",
  "The office life": "The office life",
  "The Office Life": "The Office Life",
  "The old password and the new password must be different.": "L'ancien mot de passe et le nouveau mot de passe doivent être différent.",
  "The order has been modified and validated.": "La commande a bien été modifiée et approuvée.",
  "The order has been validated.": "La commande a bien été approuvée.",
  "The password does not match this account": "Le mot de passe ne correspond pas à ce compte",
  "The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)": "8 caractères minimum dont 1 majuscule, 1 minuscule, 1 chiffre et 1 symbole",
  "The password token is incompatible. Reset and try again.": "Le jeton de mot de passe est incompatible. Réinitialisez et réessayez.",
  "The postcode must be in a valid format": "Le code postal doit être dans un format valide",
  "The user account data was successfully updated!": "The user account data was successfully updated!",
  "The user password was changed successfully updated!": "The user password was changed successfully updated!",
  "There are no shipping methods available for this country. We are sorry, please try with different country.": "There are no shipping methods available for this country. We are sorry, please try with different country.",
  "There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.": "There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.",
  "There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.": "There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.",
  "This field is required": "Ce champ est obligatoire",
  "Title": "Title",
  "To cancel": "Annuler",
  "To complete the reset of your password, please enter twice the password that will be used to log in.": "Pour compléter la réinitialisation de votre mot de passe, veuillez saisir 2 fois le mot de passe qui vous servira pour vous connecter.",
  "To get my password, I enter my email address:": "Pour obtenir mon mot de passe, je saisis mon adresse email :",
  "To validate": "Valider",
  "To": "To",
  "Total items": "Total items",
  "Total paid": "Montant payé",
  "Total price": "Total price",
  "Total": "Total",
  "Update my password": "Modifier mon mot de passe",
  "Update password": "Update password",
  "Update personal data": "Update personal data",
  "Update": "Mettre à jour",
  "url_agec": "Fiche produit relative aux qualités ou caractéristiques environnementales",
  "Use your personal data": "At Brand name, we attach great importance to privacy issues and are committed to protecting the personal data of our users. Learn more about how we care and use your personal data in the",
  "User Account": "User Account",
  "Validate my address": "Valider mon adresse",
  "Validate the quotation": "Valider l'offre",
  "Validate": "Valider",
  "View all {n} products": "Voir les {n} produits",
  "View details": "View details",
  "View the product": "Voir le produit",
  "View": "View",
  "Vue Storefront Next": "Vue Storefront Next",
  "We can't find products matching the selection.": "We can't find products matching the selection.",
  "What can we improve": "What can we improve",
  "Who we are": "Who we are",
  "Wishlist": "Wishlist",
  "Women fashion": "Women fashion",
  "Yes": "Yes",
  "You added {product} to your shopping cart.": "You added {product} to your shopping cart.",
  "You are not authorized, please log in.": "You are not authorized, please log in.",
  "You are not authorized, please log in": "You are not authorized, please log in",
  "You can change your password at any time.": "Vous pouvez à tout moment changer votre mot de passe.",
  "You can unsubscribe at any time": "You can unsubscribe at any time",
  "You currently have no orders": "You currently have no orders",
  "You have no more orders to validate.": "Vous n'avez plus de commandes en attente d'approbation.",
  "You have submitted no reviews": "You have submitted no reviews",
  "You haven’t searched for items yet": "You haven't searched for items yet.",
  "You must confirm your account. Please check your email for the confirmation link.": "You must confirm your account. Please check your email for the confirmation link.",
  "you will find message with a password reset link in your inbox.": "vous recevrez un email avec un lien pour réinitialiser votre mot de passe.",
  "Your Account": "Your account",
  "Your bag is empty": "Your bag is empty",
  "Your cart is empty": "Votre panier est vide",
  "Your current email address is": "Your current email address is",
  "Your customization": "Your customization",
  "Your e-mail": "Your e-mail",
  "Your email": "Email",
  "Your filters": "Vos filtres",
  "Your new password": "Votre nouveau mot de passe",
  "Your old password": "Votre ancien mot de passe",
  "Your password": "Votre mot de passe",
  "Your postal code": "Code postal",
  "Your Purchase": "Your Purchase",
  "Zip-code": "Zip-code",
  "Available Encours": "Encours disponible",
  "For any change in your outstanding amount, please contact your merchant": "Pour toute évolution de votre encours, veuillez prendre contact avec votre commercial"
}
